<template>
  <div>
    <AssisstantBar />
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        title="เสนอรายชื่อคณะกรรมการประเมินครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row>
            <v-col cols="12" md="8" class="font-weight-bold red--text">
              *เสนอชื่อคณะกรรมการประเมินฯ ครูผู้ช่วย ตั้งแต่รอบบรรจุ ปี 2567
              เป็นต้นไป
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_appoin"
          class="elevation-1"
          :single-expand="false"
          show-expand
          item-key="id"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <span class="text-alert">
                <v-icon @click="toggle"
                  >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                </v-icon>
                วันที่บรรจุ :
                {{
                  items[0].mt_date_app_now
                    | moment("add", "543 year")
                    | moment("D MMMM YYYY")
                }}

                อายุงาน :
                <span class="blue--text">{{
                  get_govMonth_Age(items[0].mt_date_app_now)
                }}</span>

                กำหนดส่งรายชื่อคณะกรรมการ ภายในวันที่ :
                <span class="red--text mr-5">{{
                  items[0].endDates
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}</span>

                <div v-if="!items[0].mt_committe_file">
                  <span v-if="items[0].datediffs > 30" class="red--text" dark>
                    <v-icon color="red">mdi-alert</v-icon> เกินกำหนด :
                    {{ items[0].datediffs }} วัน</span
                  >
                  <span v-else color="warning" dark>
                    <v-icon color="warning">mdi-alert</v-icon> เหลือเวลา :
                    {{ items[0].datediffs }} วัน</span
                  >
                </div>
              </span>
            </th>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <tr
                v-for="(child, index) in item.manage_assistant_teacherpf"
                :key="child.id"
              >
                <td>{{ index + 1 }}</td>
                <td colspan="3" width="40%">
                  {{ child.assNameCom }}
                </td>
                <td width="30%">
                  <span v-if="child.assistantcommitteePositon === '1'"
                    >ผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา</span
                  >
                  <span v-if="child.assistantcommitteePositon === '2'"
                    >ครูในสถานศึกษา/ใกล้เคียง</span
                  >
                </td>
              </tr>
            </td>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_admissions_status === 'missing'"
            >
              <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
            </v-chip>
            <v-chip
              dark
              color="primary"
              v-if="item.mt_admissions_status === 'receipt'"
            >
              <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
            </v-chip>
          </template>

          <template v-slot:item.mt_admissions_file="{ item }">
            <v-chip
              dark
              color="info"
              v-if="item.mt_admissions_file"
              :href="
                '/HRcommitteeAssistantTeach/' +
                  editmanage_assistant_teacher.mt_admissions_file
              "
              target="_blank"
            >
              <v-icon>mdi-printer</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_committe_status="{ item }">
            <v-icon v-if="item.mt_committe_status === 'CF'" color="primary"
              >mdi-checkbox-multiple-marked</v-icon
            >
            <v-icon v-else-if="item.mt_committe_status === 'CC'" color="red"
              >mdi-close</v-icon
            >
            <v-btn
              v-else
              color="warning"
              fab
              small
              @click="assistantcommitteeAdd(item.mt_id)"
              ><v-icon dark>mdi-account-multiple-plus</v-icon></v-btn
            >
          </template>

          <template v-slot:item.mt_committe_file="{ item }">
            <div v-if="item.mt_committe_status === 'CF'">
              <div v-if="item.mt_committe_file">
                <v-btn
                  @click="viewpdfmt_committe_file(item.mt_committe_file)"
                  color="info"
                  fab
                  small
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  @click="pdfattactment(item.mt_id)"
                  color="success"
                  rounded
                >
                  <v-icon>mdi-attachment</v-icon> แนบไฟล์
                  <v-icon color="red">mdi-alert</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else>
              <v-chip color="red" dark>
                <v-icon>mdi-alert</v-icon> ยังไม่ได้แนบไฟล์</v-chip
              >
            </div>
          </template>

          <template v-slot:item.mt_committe_datetime="{ item }">
            <div v-if="item.mt_committe_file">
              {{
                item.mt_committe_datetime
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY : HH:mm:ss a")
              }}
            </div>
            <div v-else>
              <v-chip color="red" dark>
                <v-icon>mdi-alert</v-icon> ยังไม่ได้แนบไฟล์</v-chip
              >
            </div>
          </template>

          <template v-slot:item.mt_committe_ipaapprove="{ item }">
            <div v-if="item.mt_committe_ipaapprove === 'pass'">
              <v-chip color="success" dark>
                <v-icon>mdi-checkbox-marked</v-icon>ถูกต้อง</v-chip
              >
            </div>
            <div v-else-if="item.mt_committe_ipaapprove === 'dontpass'">
              <v-chip color="red" dark>
                <v-icon>mdi-alert</v-icon> ไม่ถูกต้อง</v-chip
              >
            </div>
          </template>

          <template v-slot:item.mt_committe_ipaapprove_detail="{ item }">
            <div v-if="item.mt_committe_ipaapprove === 'dontpass'">
              {{ item.mt_committe_ipaapprove_detail }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="item.mt_collegeCC === 'CC'">
              <v-chip color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งรายงานข้อมูลผิดพลาด</v-chip
              >
              {{ item.mt_collegeCC_datail }}
            </div>
            <div v-if="item.mt_committe_status === 'CF'">
              <v-btn
                fab
                small
                v-if="item.mt_admissions_status === 'receipt'"
                color="warning"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                <v-icon>mdi-account-check</v-icon>
              </v-btn>

              <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
            </div>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model assistantcommitteeDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="assistantcommitteeDialog" persistent max-width="70%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="primary"
              icon="mdi-account-multiple-plus"
              title="กรรมการประเมิน"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistantcommitteeDialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_card
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >เลขบัตรประชาชน</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_title_s +
                            editmanage_assistant_teacher.mt_frist_name +
                            " " +
                            editmanage_assistant_teacher.mt_last_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >ชื่อ-นามสกุล</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-school
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_branch
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >สาขาวิชา :
                          {{
                            editmanage_assistant_teacher.name_branch
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-text>
              <v-card outlined class="">
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="10%" class="text-center font-weight-bold">
                          ลำดับ
                        </th>
                        <th width="40%" class="text-center font-weight-bold">
                          ผู้ประเมิน
                        </th>
                        <th width="40%" class="text-center font-weight-bold">
                          ตำแหน่ง
                        </th>
                        <th width="10%" class="text-center font-weight-bold">
                          ดำเนินการ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">1</td>
                        <td class="text-center">ผู้อำนวยการวิทยาลัย</td>
                        <td class="text-center">ประธานกรรมการโดยตำแหน่ง</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center">2</td>
                        <td class="pt-5 text-center">
                          <div v-if="editassistantcommitteeL1Status === false">
                            <span
                              v-if="
                                assistantcommitteeL1s.assistantCommitteeIDcardCommittee
                              "
                            >
                              {{
                                assistantcommitteeL1s.assistantcommitteeQualifiedTitles +
                                  assistantcommitteeL1s.assistantcommitteeQualifiedFristname +
                                  " " +
                                  assistantcommitteeL1s.assistantcommitteeQualifiedLastname
                              }}
                            </span>
                            <v-autocomplete
                              v-else
                              v-model="
                                addassistantcommittee.assistantCommitteeIDcardCommitteeL1
                              "
                              :items="assistantcommitteequalifieds"
                              item-text="assistantcommitteequalifiedNAME"
                              item-value="assistantcommitteeQualifiedIDcard"
                              label="เลือกผู้ทรงคุณวุฒิ"
                              :rules="[v => !!v || '']"
                              outlined
                            >
                              <template v-slot:item="{ item }">
                                <div class="pa-2">
                                  <div class="font-weight-black">
                                    {{ item.assistantcommitteequalifiedNAME }}
                                  </div>
                                  <div class="">
                                    {{ item.assistantcommitteeQualifiedED }} :
                                    {{
                                      item.assistantcommitteeQualifiedPosition
                                    }}
                                  </div>
                                </div>
                              </template>
                            </v-autocomplete>
                          </div>

                          <v-autocomplete
                            v-if="editassistantcommitteeL1Status === true"
                            v-model="
                              editassistantcommitteeL1.assistantCommitteeIDcardCommittee
                            "
                            :items="assistantcommitteequalifieds"
                            item-text="assistantcommitteequalifiedNAME"
                            item-value="assistantcommitteeQualifiedIDcard"
                            label="เลือกผู้ทรงคุณวุฒิ"
                            :rules="[v => !!v || '']"
                            outlined
                          >
                            <template v-slot:item="{ item }">
                              <div class="pa-2">
                                <div class="font-weight-black">
                                  {{ item.assistantcommitteequalifiedNAME }}
                                </div>
                                <div class="">
                                  {{ item.assistantcommitteeQualifiedED }} :
                                  {{ item.assistantcommitteeQualifiedPosition }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </td>
                        <td class="text-center">
                          ผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา
                        </td>
                        <td>
                          <div v-if="editassistantcommitteeL1Status === false">
                            <v-btn
                              v-if="
                                assistantcommitteeL1s.assistantCommitteeIDcardCommittee
                              "
                              fab
                              small
                              color="warning"
                              @click="assistantcommitteeL1Edit()"
                              ><v-icon>mdi-pencil</v-icon></v-btn
                            >

                            <v-btn
                              v-else
                              fab
                              small
                              color="green"
                              @click="assistantcommitteeL1Save()"
                              ><v-icon>mdi-content-save</v-icon></v-btn
                            >
                          </div>
                          <div v-else>
                            <v-btn
                              fab
                              small
                              color="green"
                              @click="assistantcommitteeL1SaveEditL1()"
                              ><v-icon>mdi-content-save</v-icon></v-btn
                            >
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">3</td>
                        <td class="pt-5 text-center">
                          <div v-if="editassistantcommitteeL2Status === false">
                            <div
                              v-if="
                                assistantcommitteeL2s.assistantCommitteeIDcardCommittee
                              "
                            >
                              <div>
                                {{
                                  assistantcommitteeL2s.title_s +
                                    assistantcommitteeL2s.frist_name +
                                    " " +
                                    assistantcommitteeL2s.last_name
                                }}
                              </div>
                              <div>
                                ตำแหน่ง
                                {{ assistantcommitteeL2s.position_name }} :
                                วิทยฐานะ {{ assistantcommitteeL2s.rang_name }}
                              </div>
                            </div>
                            <v-autocomplete
                              v-else
                              v-model="
                                addassistantcommittee.assistantCommitteeIDcardCommitteeL2
                              "
                              :items="personnel_temporarys"
                              item-text="frist_names"
                              item-value="id_card"
                              label="เลือกครูในสถานศึกษา/ใกล้เคียง"
                              :rules="[v => !!v || '']"
                              outlined
                            >
                              <template v-slot:item="{ item }">
                                <div class="pa-2">
                                  <div class="font-weight-black">
                                    {{ item.frist_names }}
                                  </div>
                                  <div>
                                    {{ item.position_name }}<span v-if="item.rang_level === '2'"
                                      >ชำนาญการ</span
                                    >
                                    <span v-else-if="item.rang_level === '3'"
                                      >ชำนาญการพิเศษ</span
                                    >
                                    <span v-else-if="item.rang_level === '4'"
                                      >เชี่ยวชาญ</span
                                    >
                                    <span v-else-if="item.rang_level === '5'"
                                      >เชี่ยวชาญพิเศษ</span
                                    >
                                  </div>
                                </div>
                              </template>
                            </v-autocomplete>
                          </div>

                          <v-autocomplete
                            v-if="editassistantcommitteeL2Status === true"
                            v-model="
                              editassistantcommitteeL2.assistantCommitteeIDcardCommittee
                            "
                            :items="personnel_temporarys"
                            item-text="frist_names"
                            item-value="id_card"
                            label="เลือกครูในสถานศึกษา/ใกล้เคียง"
                            :rules="[v => !!v || '']"
                            outlined
                          >
                            <template v-slot:item="{ item }">
                              <div class="pa-2">
                                <div class="font-weight-black">
                                  {{ item.frist_names }}
                                </div>
                                <div class="">
                                  {{ item.position_name }} <span v-if="item.rang_level === '2'"
                                    >ชำนาญการ</span
                                  >
                                  <span v-else-if="item.rang_level === '3'"
                                    >ชำนาญการพิเศษ</span
                                  >
                                  <span v-else-if="item.rang_level === '4'"
                                    >เชี่ยวชาญ</span
                                  >
                                  <span v-else-if="item.rang_level === '5'"
                                    >เชี่ยวชาญพิเศษ</span
                                  >
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </td>

                        <td class="text-center">ครูในสถานศึกษา/ใกล้เคียง</td>
                        <td>
                          <div v-if="editassistantcommitteeL2Status === false">
                            <v-btn
                              v-if="
                                assistantcommitteeL2s.assistantCommitteeIDcardCommittee
                              "
                              fab
                              small
                              color="warning"
                              @click="assistantcommitteeL2Edit()"
                              ><v-icon>mdi-pencil</v-icon></v-btn
                            >

                            <v-btn
                              v-else
                              fab
                              small
                              color="green"
                              @click="assistantcommitteeL2Save()"
                              ><v-icon>mdi-content-save</v-icon></v-btn
                            >
                          </div>
                          <div v-else>
                            <v-btn
                              fab
                              small
                              color="green"
                              @click="assistantcommitteeL2SaveEditL2()"
                              ><v-icon>mdi-content-save</v-icon></v-btn
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="text-right pa-2">
                  <v-btn
                    v-if="
                      assistantcommitteeL1s.assistantCommitteeIDcardCommittee &&
                        assistantcommitteeL2s.assistantCommitteeIDcardCommittee
                    "
                    rounded
                    color="info"
                    :href="
                      '#/college/print_assistantcommittee_present/?mt_id_card=' +
                        editmanage_assistant_teacher.mt_id_card +
                        '&mt_times=' +
                        editmanage_assistant_teacher.mt_times +
                        '&mt_years=' +
                        editmanage_assistant_teacher.mt_years +
                        '&mt_id=' +
                        editmanage_assistant_teacher.mt_id
                    "
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon> พิมพ์แบบเสนอรายชื่อ
                  </v-btn>
                </div>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="assistantcommitteeDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  assistantcommitteeL1s.assistantCommitteeIDcardCommittee &&
                    assistantcommitteeL2s.assistantCommitteeIDcardCommittee
                "
                color="primary"
                @click.stop="assistantcommitteeDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึกยืนยันข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherAttactmentDialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="manage_assistant_teacherAttachmentDialog"
          persistent
          max-width="70%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="primary"
              icon="mdi-account-multiple-plus"
              title="แนบไฟล์หนังสือนำส่ง เสนอรายชื่อกรรมการประเมิน"
              class="px-5 py-3"
            ></base-material-card>

            <v-card-text>
              <v-container grid-list-md>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo">
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        editmanage_assistant_teacher.mt_id_card
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >เลขบัตรประชาชน</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon color="indigo">
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        editmanage_assistant_teacher.mt_title_s +
                          editmanage_assistant_teacher.mt_frist_name +
                          " " +
                          editmanage_assistant_teacher.mt_last_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>ชื่อ-นามสกุล</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon color="indigo">
                        mdi-school
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        editmanage_assistant_teacher.mt_id_branch
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >สาขาวิชา :
                        {{
                          editmanage_assistant_teacher.name_branch
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-card-text>
            <v-card-text>
              <v-card>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="10%" class="text-center">
                          ลำดับ
                        </th>
                        <th width="40%" class="text-center">
                          ผู้ประเมิน
                        </th>
                        <th width="40%" class="text-center">
                          ตำแหน่ง
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">1</td>
                        <td class="text-center">ผู้อำนวยการวิทยาลัย</td>
                        <td class="text-center">ประธานกรรมการโดยตำแหน่ง</td>
                      </tr>
                      <tr>
                        <td class="text-center">3</td>
                        <td class="pt-5 text-center">
                          <div v-if="editassistantcommitteeL2Status === false">
                            <div
                              v-if="
                                assistantcommitteeL2s.assistantCommitteeIDcardCommittee
                              "
                            >
                              <div>
                                {{
                                  assistantcommitteeL2s.title_s +
                                    assistantcommitteeL2s.frist_name +
                                    " " +
                                    assistantcommitteeL2s.last_name
                                }}
                              </div>
                              <div>
                                ตำแหน่ง
                                {{ assistantcommitteeL2s.position_name }} :
                                วิทยฐานะ {{ assistantcommitteeL2s.rang_name }}
                              </div>
                            </div>
                            <v-autocomplete
                              v-else
                              v-model="
                                addassistantcommittee.assistantCommitteeIDcardCommitteeL2
                              "
                              :items="personnel_temporarys"
                              item-text="frist_names"
                              item-value="id_card"
                              label="เลือกครูในสถานศึกษา/ใกล้เคียง"
                              :rules="[v => !!v || '']"
                              outlined
                            >
                              <template v-slot:item="{ item }">
                                <div class="pa-2">
                                  <div class="font-weight-black">
                                    {{ item.frist_names }}
                                  </div>
                                  <div>
                                    <span v-if="item.rang_level === '2'"
                                      >ชำนาญการ</span
                                    >
                                    <span v-else-if="item.rang_level === '3'"
                                      >ชำนาญการพิเศษ</span
                                    >
                                    <span v-else-if="item.rang_level === '4'"
                                      >เชี่ยวชาญ</span
                                    >
                                    <span v-else-if="item.rang_level === '5'"
                                      >เชี่ยวชาญพิเศษ</span
                                    >
                                  </div>
                                </div>
                              </template>
                            </v-autocomplete>
                          </div>

                          <v-autocomplete
                            v-if="editassistantcommitteeL2Status === true"
                            v-model="
                              editassistantcommitteeL2.assistantCommitteeIDcardCommittee
                            "
                            :items="personnel_temporarys"
                            item-text="frist_names"
                            item-value="id_card"
                            label="เลือกครูในสถานศึกษา/ใกล้เคียง"
                            :rules="[v => !!v || '']"
                            outlined
                          >
                            <template v-slot:item="{ item }">
                              <div class="pa-2">
                                <div class="font-weight-black">
                                  {{ item.frist_names }}
                                </div>
                                <div class="">
                                  <span v-if="item.rang_level === '2'"
                                    >ชำนาญการ</span
                                  >
                                  <span v-else-if="item.rang_level === '3'"
                                    >ชำนาญการพิเศษ</span
                                  >
                                  <span v-else-if="item.rang_level === '4'"
                                    >เชี่ยวชาญ</span
                                  >
                                  <span v-else-if="item.rang_level === '5'"
                                    >เชี่ยวชาญพิเศษ</span
                                  >
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </td>

                        <td class="text-center">ครูในสถานศึกษา/ใกล้เคียง</td>
                      </tr>
                      <tr>
                        <td class="text-center">2</td>
                        <td class="pt-5 text-center">
                          <div v-if="editassistantcommitteeL1Status === false">
                            <span
                              v-if="
                                assistantcommitteeL1s.assistantCommitteeIDcardCommittee
                              "
                            >
                          
                              {{
                                assistantcommitteeL1s.assistantcommitteeQualifiedTitles +
                                  assistantcommitteeL1s.assistantcommitteeQualifiedFristname +
                                  " " +
                                  assistantcommitteeL1s.assistantcommitteeQualifiedLastname
                              }}
<br>
{{ assistantcommitteeL1s.assistantcommitteeQualifiedPosition }}

{{ assistantcommitteeL1s.assistantcommitteeQualifiedCollegeMain }}

                            </span>
                            <v-autocomplete
                              v-else
                              v-model="
                                addassistantcommittee.assistantCommitteeIDcardCommitteeL1
                              "
                              :items="assistantcommitteequalifieds"
                              item-text="assistantcommitteequalifiedNAME"
                              item-value="assistantcommitteeQualifiedIDcard"
                              label="เลือกผู้ทรงคุณวุฒิ"
                              :rules="[v => !!v || '']"
                              outlined
                            >
                              <template v-slot:item="{ item }">
                                <div class="pa-2">
                                  <div class="font-weight-black">
                                    {{ item.assistantcommitteequalifiedNAME }}
                                  </div>
                                  <div class="">
                                    {{ item.assistantcommitteeQualifiedED }} :
                                    {{
                                      item.assistantcommitteeQualifiedPosition
                                    }}
                                  </div>
                                </div>
                              </template>
                            </v-autocomplete>
                          </div>

                          <v-autocomplete
                            v-if="editassistantcommitteeL1Status === true"
                            v-model="
                              editassistantcommitteeL1.assistantCommitteeIDcardCommittee
                            "
                            :items="assistantcommitteequalifieds"
                            item-text="assistantcommitteequalifiedNAME"
                            item-value="assistantcommitteeQualifiedIDcard"
                            label="เลือกผู้ทรงคุณวุฒิ"
                            :rules="[v => !!v || '']"
                            outlined
                          >
                            <template v-slot:item="{ item }">
                              <div class="pa-2">
                                <div class="font-weight-black">
                                  {{ item.assistantcommitteequalifiedNAME }}
                                </div>
                                <div class="">
                                  {{ item.assistantcommitteeQualifiedED }} :
                                  {{ item.assistantcommitteeQualifiedPosition }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </td>
                        <td class="text-center">
                          ผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา
                        </td>
                      </tr>
                  
                    </tbody>
                  </template>
                </v-simple-table>

                <div class="text-right">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/college/print_assistantcommittee_present/?mt_id_card=' +
                        editmanage_assistant_teacher.mt_id_card +
                        '&mt_times=' +
                        editmanage_assistant_teacher.mt_times +
                        '&mt_years=' +
                        editmanage_assistant_teacher.mt_years +
                        '&mt_id=' +
                        editmanage_assistant_teacher.mt_id
                    "
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon> พิมพ์แบบเสนอรายชื่อ
                  </v-btn>
                </div>

                <v-form
                  ref="manage_assistant_teacherAttactmentDialogform"
                  lazy-validation
                >
                  <div class="pa-2">
                    <v-file-input
                      v-model="mt_committe_file"
                      accept=".pdf"
                      name="mt_committe_file"
                      color="deep-purple accent-4"
                      counter
                      label="ไฟล์เสนอรายชื่อกรรมการ .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      rounded
                      :show-size="1000"
                      :rules="rules"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </div>
                </v-form>
              </v-card>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="manage_assistant_teacherAttachmentDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                color="primary"
                @click="assistantcommitteeFileattachmentDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึกยืนยันข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model committeeCCdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="committeeCCdialog" persistent max-width="40%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ขอยกเลิกแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="committeeCCform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editmanage_assistant_teacher.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ editmanage_assistant_teacher.mt_id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ editmanage_assistant_teacher.mt_title_s
                        }}{{ editmanage_assistant_teacher.mt_frist_name }}
                        {{ editmanage_assistant_teacher.mt_last_name }}

                        <br />
                        วันที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_date_app_now }}
                        <br />
                        คำสั่งที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_order_app }}
                      </div>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="เหตุผลในการขอยกเลิก"
                        v-model="
                          editmanage_assistant_teacher.mt_collegeCC_datail
                        "
                        outlined
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green"
                @click.stop="committeeCCdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon
                >&nbsp;ยืนยันยกเลิก
              </v-btn>
              <v-btn
                outlined
                color="warning"
                @click.stop="committeeCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfmt_committe_filedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfmt_committe_filedialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRcommitteeAssistantTeach/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import AssisstantBar from "../../components/college/assisstantBar.vue";

export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      expanded: [],
      singleExpand: false,
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      assistantcommitteeDialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      editmanage_assistant_teacher: [],
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "วันที่บรรจุ", align: "center", value: "mt_appoin" },

        { text: "สาขาวิชา", align: "center", value: "mt_id_branch" },
        { text: "รหัสบัตร", align: "center", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "center", value: "mt_name" },
        { text: "วุฒิการศึกษา", align: "center", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "center", value: "mt_ed_name" },

        { text: "สถานะ", align: "center", value: "mt_admissions_status" },
        {
          text: "กรรมการประเมิน",
          align: "center",
          value: "mt_committe_status"
        },

        {
          text: "ไฟล์เสนอ",
          align: "center",
          value: "mt_committe_file"
        },

        {
          text: "วันที่แนบเสนอ",
          align: "center",
          value: "mt_committe_datetime"
        },
        {
          text: "การตรวจสอบเอกสาร",
          align: "center",
          value: "mt_committe_ipaapprove"
        },

        {
          text: "หมายเหตุ",
          align: "center",
          value: "mt_committe_ipaapprove_detail"
        },

        {
          text: "ดำเนินการ",
          align: "center",
          value: "actions"
        },
        {
          text: "รายชื่อกรรมการ",
          align: "center",
          value: "data-table-expand",
          width: "10%"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      data_syslog: {},
      mt_admissions_status_select: [
        { text: "มารายงานตัว", value: "receipt" },
        { text: "ไม่มารายงานตัว", value: "missing" }
      ],
      mt_examtype_select: [
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "แข่งขัน(ภายนอก)", value: "recruit" }
      ],
      mt_examgroup_select: [
        { text: "ทั่วไป", value: "general" },
        { text: "ชายแดนภาคใต้", value: "southern" }
      ],
      collegeCheck: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB",
        value => !!value || ""
      ],
      assistantcommitteequalifieds: [],
      personnel_temporarys: [],
      addassistantcommittee: {},
      editassistantcommitteeL1: [],
      editassistantcommitteeL1Update: {},
      editassistantcommitteeL2: [],
      editassistantcommitteeL2Update: {},
      editassistantcommitteeL1Status: false,
      editassistantcommitteeL2Status: false,
      manage_assistant_teacherAttachmentDialog: false,
      assistantcommitteeL1s: [],
      assistantcommitteeL2s: [],
      personnel_temporarysSeacrh: [],
      linealerts: {},
      mt_committe_file: [],
      pdfmt_committe_filedialog: false,
      pdf_files: [],
      massege_cc: [],
      committeeCCdialog: false
    };
  },
  async mounted() {
    await this.collegeQuery();
    await this.manage_assistant_teacherQueryAll();
    await this.personnel_temporaryQuery();
    await this.assistantcommitteequalifiedQuery();
  },
  methods: {
    async viewpdfmt_committe_file(mt_committe_file) {
      this.pdf_files = mt_committe_file;

      this.pdfmt_committe_filedialog = true;
    },
    async pdfattactment(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      await this.assistantcommitteeL1Query();
      await this.assistantcommitteeL2Query();
      this.manage_assistant_teacherAttachmentDialog = true;
    },

    async committeeCCdialogSubmit() {
      if (this.$refs.committeeCCform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.editmanage_assistant_teacher.mt_collegeCC = "CC";
        this.editmanage_assistant_teacher.mt_committe_status = "CC";
        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });

          this.linealerts.ApiKey = this.ApiKey;
          this.linealerts.college_name = this.collegeCheck.college_name;
          this.linealerts.details = String(
            "งานครูผู้ช่วย : ขอยกเลิกแนบไฟล์คำสั่งแต่งตั้งคณะกรรมการ ประเมินครูผู้ช่วย : " +
              this.editmanage_assistant_teacher.mt_id_card +
              " : " +
              this.editmanage_assistant_teacher.mt_title_s +
              this.editmanage_assistant_teacher.mt_frist_name +
              " " +
              this.editmanage_assistant_teacher.mt_last_name
          );
          this.linealerts.datetime = this.date_today;

          let result_line = await this.$http.post(
            "linenotify_hr.php",
            this.linealerts
          );

          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.manage_assistant_teacherQueryAll();
        this.committeeCCdialog = false
      }
    },
    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.committeeCCdialog = true;
      /*      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
      Swal.fire({
        title: "ขอยกเลิกเสนอรายชื่อคณะกรรมการ",
        text: textAlert,
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        preConfirm: async masseges => {
          let massege = String(masseges);
          if (massege.length > 1) {
            this.massege_cc = massege;
          } else {
            Swal.showValidationMessage(`กรุณาป้อนเหตุผล`);
          }
        }
      }).then(async result => {
        if (result.isConfirmed) {
          
        
      }); */
    },

    async assistantcommitteeFileattachmentDialogSubmit() {
      if (this.$refs.manage_assistant_teacherAttactmentDialogform.validate()) {
        let result = "";
        let uploaded = null;
        Swal.fire({
          title: "ยืนยันการเสนอรายชื่อกรรมการประเมินครูผู้ช่วย",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
            if (this.mt_committe_file != "") {
              let formData = new FormData();
              let filename =
                this.editmanage_assistant_teacher.mt_id_card +
                "." +
                this.time_stamp +
                "." +
                "assistantCommittee.pdf";
              formData.append("ApiKey", this.ApiKey);
              formData.append("file", this.mt_committe_file);
              formData.append(
                "filename",
                "../HRcommitteeAssistantTeach/" + filename
              );
              result = await this.$http.post("uploadfile.php", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
              this.editmanage_assistant_teacher.mt_committe_file = filename;
              uploaded = true;
            } else {
              uploaded = false;
            }
          } else {
            upload = false;
          }
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
          this.editmanage_assistant_teacher.mt_id = this.editmanage_assistant_teacher.mt_id;
          this.editmanage_assistant_teacher.mt_committe_datetime = new Date();
          this.editmanage_assistant_teacher.mt_committe_ipaapprove = "";
          this.editmanage_assistant_teacher.mt_committe_datetime = new Date();
          result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );
          if (result.data.status == true && uploaded == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.manage_assistant_teacherQueryAll();
          } else {
            if (!uploaded && upload) {
              Swal.fire({
                icon: "warning",
                title: "แนบไฟล์ไม่ถูกต้อง",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
        this.manage_assistant_teacherAttachmentDialog = false;
      }
    },

    async assistantcommitteeL1Edit() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.editmanage_assistant_teacher.mt_times,
        assistantcommitteeYear: this.editmanage_assistant_teacher.mt_years,
        assistantcommitteeIDCardTeach: this.editmanage_assistant_teacher
          .mt_id_card,
        assistantcommitteePositon: "1"
      });
      this.editassistantcommitteeL1 = result.data;
      this.editassistantcommitteeL1Status = true;
    },
    async assistantcommitteeL1SaveEditL1() {
      if (this.$refs.assistantcommitteeDialogform.validate()) {
        this.editassistantcommitteeL1Update.ApiKey = this.ApiKey;
        this.editassistantcommitteeL1Update.assistantcommitteeTime = this.editmanage_assistant_teacher.mt_times;
        this.editassistantcommitteeL1Update.assistantcommitteeYear = this.editmanage_assistant_teacher.mt_years;
        this.editassistantcommitteeL1Update.assistantcommitteeIDCardTeach = this.editassistantcommitteeL1.assistantcommitteeIDCardTeach;
        this.editassistantcommitteeL1Update.assistantCommitteeIDcardCommittee = this.editassistantcommitteeL1.assistantCommitteeIDcardCommittee;
        this.editassistantcommitteeL1Update.assistantcommitteePositon = "1";
        let result = await this.$http.post(
          "assistantcommittee.update.php",
          this.editassistantcommitteeL1Update
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editassistantcommitteeL1Status = false;
        await this.assistantcommitteeL1Query();
      }
    },
    async assistantcommitteeL1Query() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.editmanage_assistant_teacher.mt_times,
        assistantcommitteeYear: this.editmanage_assistant_teacher.mt_years,
        assistantcommitteeIDCardTeach: this.editmanage_assistant_teacher
          .mt_id_card,
        assistantcommitteePositon: 1
      });
      this.assistantcommitteeL1s = result.data;
    },
    async assistantcommitteeL1Save() {
      if (this.$refs.assistantcommitteeDialogform.validate()) {
        this.addassistantcommittee.ApiKey = this.ApiKey;
        this.addassistantcommittee.assistantcommitteeTime = this.editmanage_assistant_teacher.mt_times;
        this.addassistantcommittee.assistantcommitteeYear = this.editmanage_assistant_teacher.mt_years;
        this.addassistantcommittee.assistantcommitteeCollege = this.collegeCheck.college_code;
        this.addassistantcommittee.assistantcommitteeIDCardTeach = this.editmanage_assistant_teacher.mt_id_card;
        this.addassistantcommittee.assistantCommitteeIDcardCommittee = this.addassistantcommittee.assistantCommitteeIDcardCommitteeL1;
        this.addassistantcommittee.assistantcommitteePositon = "1";
        let result = await this.$http.post(
          "assistantcommittee.insert.php",
          this.addassistantcommittee
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        await this.assistantcommitteeL1Query();
      }
    },
    async assistantcommitteeL2Edit() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.editmanage_assistant_teacher.mt_times,
        assistantcommitteeYear: this.editmanage_assistant_teacher.mt_years,
        assistantcommitteeIDCardTeach: this.editmanage_assistant_teacher
          .mt_id_card,
        assistantcommitteePositon: "2"
      });
      this.editassistantcommitteeL2 = result.data;
      this.editassistantcommitteeL2Status = true;
    },
    async assistantcommitteeL2SaveEditL2() {
      if (this.$refs.assistantcommitteeDialogform.validate()) {
        this.editassistantcommitteeL2Update.ApiKey = this.ApiKey;
        this.editassistantcommitteeL2Update.assistantcommitteeTime = this.editmanage_assistant_teacher.mt_times;
        this.editassistantcommitteeL2Update.assistantcommitteeYear = this.editmanage_assistant_teacher.mt_years;
        this.editassistantcommitteeL2Update.assistantcommitteeIDCardTeach = this.editassistantcommitteeL2.assistantcommitteeIDCardTeach;
        this.editassistantcommitteeL2Update.assistantCommitteeIDcardCommittee = this.editassistantcommitteeL2.assistantCommitteeIDcardCommittee;
        this.editassistantcommitteeL2Update.assistantcommitteePositon = "2";
        let result = await this.$http.post(
          "assistantcommittee.update.php",
          this.editassistantcommitteeL2Update
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editassistantcommitteeL2Status = false;
        await this.assistantcommitteeL2Query();
      }
    },
    async assistantcommitteeL2Query() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.editmanage_assistant_teacher.mt_times,
        assistantcommitteeYear: this.editmanage_assistant_teacher.mt_years,
        assistantcommitteeIDCardTeach: this.editmanage_assistant_teacher
          .mt_id_card,
        assistantcommitteePositon: 2
      });
      this.assistantcommitteeL2s = result.data;
    },
    async assistantcommitteeL2Save() {
      if (this.$refs.assistantcommitteeDialogform.validate()) {
        this.addassistantcommittee.ApiKey = this.ApiKey;
        this.addassistantcommittee.assistantcommitteeTime = this.editmanage_assistant_teacher.mt_times;
        this.addassistantcommittee.assistantcommitteeYear = this.editmanage_assistant_teacher.mt_years;
        this.addassistantcommittee.assistantcommitteeCollege = this.collegeCheck.college_code;
        this.addassistantcommittee.assistantcommitteeIDCardTeach = this.editmanage_assistant_teacher.mt_id_card;
        this.addassistantcommittee.assistantCommitteeIDcardCommittee = this.addassistantcommittee.assistantCommitteeIDcardCommitteeL2;
        this.addassistantcommittee.assistantcommitteePositon = "2";
        let result = await this.$http.post(
          "assistantcommittee.insert.php",
          this.addassistantcommittee
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        await this.assistantcommitteeL2Query();
      }
    },
    async personnel_temporarySeaQ() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: assistantCommitteeIDcardCommittee
      });
      this.personnel_temporarysSeacrh = result.data;
    },

    async personnel_temporaryQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        regiongroup_ID: this.collegeCheck.regiongroup_ID,
        rang_level_check: "ok"
      });
      this.personnel_temporarys = result.data;
    },

    async assistantcommitteequalifiedQuery() {
      let result = await this.$http.post("assistantcommitteequalified.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeQualifiedCollege: this.collegeCheck.college_code
      });
      this.assistantcommitteequalifieds = result.data;
    },
    async assistantcommitteeAdd(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      await this.assistantcommitteeL1Query();
      await this.assistantcommitteeL2Query();
      this.assistantcommitteeDialog = true;
    },
    async manage_assistant_teacherCC() {
      this.assistantcommitteeDialog = true;
    },
    async assistantcommitteeDialogSubmit() {
      this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
      this.editmanage_assistant_teacher.mt_committe_status = "CF";
      this.editmanage_assistant_teacher.mt_admissions_date = new Date();
      let result = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.editmanage_assistant_teacher
      );
      if (result.data.status == true) {
        this.manage_assistant_teacher = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.manage_assistant_teacherQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.manage_assistant_teacherQueryAll();
      this.assistantcommitteeDialog = false;
      this.editmanage_assistant_teacherdialog = false;
    },
    async collegeQuery() {
      let result = {};
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("college.php", {
        college_code: userSession.user_code,
        ApiKey: this.ApiKey
      });
      this.collegeCheck = result.data;
    },
    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    },
    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_college_code: this.collegeCheck.college_code,
          mt_admissions_status: "receipt"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    
    },

    get_govMonth_Age(date_app_now) {
      let DOB = new Date(date_app_now);
      var today = today || new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    }
  },
  computed: {
    time_stamp() {
      let time = Date.now();
      return time;
    },
    color() {
      return "blue-grey";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { AssisstantBar }
};
</script>

<style scoped>
.text-alert {
  font-size: 14pt;
}
</style>
